var wipwrp = window.wipwrp || {};

wipwrp.rappresentate = (function($,mzr,doc,win){

	/* ----------------- private vars ---------------*/

	var	self				=	{};

	/* ----------------- public methods ---------------*/

	self.init = function(){
		$('form').parsley();
		wipwrp.autocomplete.init();
		wipwrp.autocomplete.init({
			selector: ".autocomplete-prefisso",
			valueinfield: true
		});
		wipwrp.autocomplete.init({
			selector: ".autocomplete-provincia",
			valueinfield: true
		});
		wipwrp.accordion.init();

		wipwrp.utils.uppercaseControl('[data-textcontrol="uppercase"]');
		wipwrp.utils.lowercaseControl('[data-textcontrol="lowercase"]');
		wipwrp.utils.capitalizeControl('[data-textcontrol="capitalize"]');
		
		_controllaNazione('nazione_azienda_catalogo_value','provincia_azienda_catalogo_value','provincia_azienda_catalogo','controlgroup_provincia_catalogo','controlgroup_nazione_catalogo');
		$('#nazione_azienda_catalogo').blur(function(e){_controllaNazione('nazione_azienda_catalogo_value','provincia_azienda_catalogo_value','provincia_azienda_catalogo','controlgroup_provincia_catalogo','controlgroup_nazione_catalogo')});
	}

	//DUPLICATO DELLA FUNZIONE _controllaNazione in domanda.js.
	//se alla fine dei lavori irmane uguale possiamo anche spostarle entrambe in common.js
	
	function _controllaNazione(nazione_nascosta,provincia_nascosta,provincia_visibile,controlgroup_provincia,controlgroup_nazione){
		if($('#'+nazione_nascosta+'').val()!='ITA'){
			$('#'+provincia_visibile+'').removeAttr('required');
			$('#'+provincia_nascosta+'').val('');
			$('#'+controlgroup_provincia+'').hide();
			$('#'+controlgroup_nazione+'').removeClass('col_3').addClass('col_6');
		}else{
			$('#'+provincia_visibile+'').val('').attr('required','');
			$('#'+provincia_nascosta+'').val('');
			$('#'+controlgroup_provincia+'').show();
			$('#'+controlgroup_nazione+'').removeClass('col_6').addClass('col_3');
		}
	}

	/* ----------------- public methods ---------------*/

	return self;

})(jQuery,Modernizr, document, window);